import { ReactElement, useState } from "react";

import "../../../Styles/specialClasses.css"
import SlideshowPage from "./SlideshowPage";

const validTransitions = ["fadeIn","slide-leftToRight", "slide-rightToLeft", "slide-topToBottom", "slide-bottomToTop"] as const;
export type Transition = typeof validTransitions[number];

interface SlideshowController {
    transition: Transition,

    pageI: number,
    setPageI: Function,

    pages: ReactElement[],
    setPages: Function,
    addPage: Function,
    
    nextPage: Function,
    prevPage: Function
}

export const useSlideshowController = (elems?: ReactElement[], transition: Transition = "fadeIn") => {

    let [pages, setPages] = useState<ReactElement[]>(elems || [])
    let [pageI, setPageI] = useState(0)

    const result: SlideshowController = {
        transition: transition,

        pageI: pageI,
        setPageI: setPageI,

        pages: pages,
        setPages: (elems: ReactElement[]) => {
            setPages(elems)
        },
        addPage: (elem: ReactElement) => {
            setPages([...pages,elem])
        },

        nextPage: async () => { //TODO: Fix!!! MEANING FIGURE OUT HOW TO OPERATE THIS IN STRICT MODE
            if (!pages) {noElemErr();return}
            /*let temp = structuredClone(pageI)
            let nextI = temp + 1 < pages.length ? temp + 1 : 0
            temp = nextI*/
            setPageI(prev => prev + 1 < pages.length ? prev + 1 : 0)
            return
        },

        prevPage: () => { //TODO: Fix!!! MEANING FIGURE OUT HOW TO OPERATE THIS IN STRICT MODE
            if (!pages) {noElemErr();return}
            /*let temp = pageI
            let nextI = pageI - 1 > 0 ? pageI - 1 : pages.length - 1
            temp = nextI*/
            setPageI(prev => prev - 1 > 0 ? prev - 1 : pages.length - 1)
        }
    }

    return result
}

const noElemErr = () => {
    throw Error("ERR: Slideshow has no elements")
}

interface SlideshowProps {
    controller: SlideshowController
}

function Slideshow({controller}: SlideshowProps) {

    const mapPages = (pages: ReactElement[]) => {
        let i = -1;
        return pages.map(e => {
            i++;
            return <SlideshowPage element={e} inProp={controller.pageI === i} transition={controller.transition}/>
        })
    }

    return ( 
        <div style={{position: "relative", backgroundColor: "green"}} className="centerAlign">
            {mapPages(controller.pages)}
        </div>
    );
}

export default Slideshow;