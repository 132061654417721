import "../../Styles/animWave.css"

function AnimWave() {
    return ( <div className="ocean">
        <svg className="animWave" viewBox="0 0 3840 200" xmlns="http://www.w3.org/2000/svg">
            <path d="M0,100 C240,150 480,50 720,100 C960,150 1200,50 1440,100 C1680,150 1920,50 2160,100 C2400,150 2640,50 2880,100 C3120,150 3360,50 3600,100 C3840,150 4080,50 4320,100 V200 H0 Z" fill="#dedede"/>
        </svg>

        <svg className="animWave" viewBox="0 0 3840 200" xmlns="http://www.w3.org/2000/svg">
            <path d="M0,100 C240,150 480,50 720,100 C960,150 1200,50 1440,100 C1680,150 1920,50 2160,100 C2400,150 2640,50 2880,100 C3120,150 3360,50 3600,100 C3840,150 4080,50 4320,100 V200 H0 Z"  fill="#ffffff"/>
        </svg>
      </div> );
}

export default AnimWave;