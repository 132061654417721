export interface Team {
    teamName: string,
    img: string,
    school: string
}

interface Props {
    teams: Team[]
}

function Podium({teams}: Props) {
    return ( 
        <div className="podium evenSpacing row">

                {[1,0,2].map(i => // order in which the teams appear
                    <div className="centerAlign column team">
                        <img src={teams[i].img} alt={"Ikona týmu " + teams[i].teamName}/>
                        <h2>{teams[i].teamName}</h2>
                        <h4>{teams[i].school}</h4>
                    </div>
                )}
                

            </div>
     );
}

export default Podium;