import { useEffect, useState } from "react";
import WelcomeAnimation from "../Components/General/WelcomeAnimation";
import Header from "../Components/Homepage/Header";
import Info from "../Components/Homepage/Info";

import "../Styles/homepage.css"
import FAQPage from "../Components/Homepage/FAQPage";
import Menu from "../Components/Homepage/Menu"
import AnimSeparator from "../Components/Animated/AnimSeparator";
import Contact from "../Components/Homepage/Contact";
import Harmonogram from "../Components/Homepage/Harmonogram";
import Cookies from "universal-cookie";

function Homepage() {

    let [contentVisible, setContentVisible] = useState(false)

    let cookies = new Cookies()

    const welcomeAnimDuration = cookies.get("disableAnim") ? 0 : 4000

    useEffect(() => {
        setTimeout(() => {setContentVisible(true); cookies.set("disableAnim", "true", { path: "/", maxAge: 86400 })}, welcomeAnimDuration)
    })

    return ( 
        <>
            {!(cookies.get("disableAnim")) && <WelcomeAnimation/>}
            <Header delayMs={welcomeAnimDuration-1000}/>
            {contentVisible && <>
                <Info/>
                <AnimSeparator/>
                <FAQPage/>
                <Menu/>
                <Contact/>
            </>}
        </>

    );
}

export default Homepage;