import FAQRow from "../General/FAQ/FAQRow";
import Podium, { Team } from "./Podium";

const results = require("../../Assets/archive/season1/results.csv")

function Archive2024() {

    console.log(results)

    const teams: Team[] = [
        {
            teamName: "Twix",
            school: "Gymnázium Matyáše Lercha, Brno",
            img: "https://i.imgur.com/GtV0WrS.jpeg"
        },
        {
            teamName: "Pink Panthers",
            school: "Gymnázium Jírovcova, České Budějovice",
            img: "https://static.smoulasoutez.cz/logo/noBG/whiteText.png"
        },
        {
            teamName: "Smažení pražští orangutani",
            school: "Nový PORG, Praha",
            img: "https://static.smoulasoutez.cz/logo/noBG/whiteText.png"
        },
    ]

    return ( 
        <div className="archivePage" id="archive2024">
            <h2>ŠMouLa v0.1</h2>

            <Podium teams={teams}/>
            <FAQRow data={{title: "Kompletní výsledky", desc: <div>ahoj</div>}}/>
        
            <p>Náš první ročník ŠMouLy! lolololo něco sentimental I guess loool</p>
        </div>
     );
}

export default Archive2024;