import "../../Styles/animBg.scss"

function AnimBg() {
  return (
    <>
    

    <div className="animBg">
    <div style={{width: "100vw", height: "100vh", position:"absolute", backgroundColor: "rgba(0,0,0,0.5)"}}/>
      <div className="bubbles">
      {/*<div className="bubble"></div>1
        <div className="bubble"></div>2
        <div className="bubble"></div>3
        <div className="bubble"></div>4
        <div className="bubble"></div>5
        <div className="bubble"></div>6
        <div className="bubble"></div>7
        <div className="bubble"></div>8
        <div className="bubble"></div>9
        <div className="bubble"></div>10
        <div className="bubble"></div>11
        <div className="bubble"></div>12
        <div className="bubble"></div>13
        <div className="bubble"></div>14
        <div className="bubble"></div>15
        <div className="bubble"></div>16
        <div className="bubble"></div>17
        <div className="bubble"></div>18
        <div className="bubble"></div>19
        <div className="bubble"></div>20*/}
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
      </div>
    </div>
    </>
  );
}

export default AnimBg;
