import { ReactElement, useState, useRef, useEffect } from "react";
import { CSSTransition } from 'react-transition-group';
import { Transition } from "./Slideshow";

interface Props {
    element: ReactElement
    inProp: boolean,
    transition: Transition
}

function SlideshowPage({element, inProp, transition}: Props) {

    const nodeRef = useRef(null);
    let [inState, setInState] = useState(false)

    useEffect(() => {
        setInState(inProp)
    }, [inProp]) //I honestly have zero idea how or why this works but Im not gonna complain yk

    return ( 
    <CSSTransition nodeRef={nodeRef} in={inState} timeout={200} classNames={transition}>
        <div ref={nodeRef} style={{position: "absolute"}} className={transition + "-enter slideshowPage"}>
        {element ? element : <></>}
        </div>
    </CSSTransition>
     );
}

export default SlideshowPage;