function Skibidi() {
    return (
        <>
            <h1>Co tu děláš???</h1>
            <h2><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></h2>
            <h3>Dobře no, tak jsme skibidi...</h3>
        </>
    );
}

export default Skibidi;