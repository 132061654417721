import Archive2024 from "../Components/ArchivePage/Archive2024";
import "../Styles/archivePage.css"

function ArchivePage() {

    const years = [{
        year: 2024,
        elem: <Archive2024/>
    }]

    return ( 
    <div className="archivePage centerAlign column">

        <h1>Archiv</h1>

        <div className="menu">
            {years.map(y => <a href={"#archive"+y.year}>{y.year}</a>)}
        </div>

        <div>
            {years.map(y => y.elem)}
        </div>

    </div>
     );
}

export default ArchivePage;