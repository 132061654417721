import { Link, useLocation } from "react-router-dom";
import AnimBg from "../Components/Animated/AnimBg";

function PageNotFound() {

    const location = useLocation()

    return ( 
        <>
        <AnimBg/>
        <div className="container centerAlign column" style={{position: "absolute", top: "0"}}>
            <h1>Stránka <code>{location.pathname}</code> buď neexistuje, nebo na ní nemáš přístup</h1>
            <Link to="/">
              <button
                type="button"
                className="btn btn-primary">
                Zpět na domovskou stránku
              </button>
            </Link>
        </div>
        </>
     );
}

export default PageNotFound;