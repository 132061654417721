import GoBack from "../Components/General/GoBack";
import { useEffect } from "react";

function GameDetailsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="column centerAlign gameDetailsPage" style={{ marginTop: "7vh" }}>
      <GoBack link="/" />
      <h1>Hra</h1>

      <p>
        Hlavním cílem soutěže <i>ŠM</i>ou<i>L</i>a je získat co nejvíce bodů, přičemž maximální zisk bodů je <i>1133</i>.
        <br />
        Soutěž trvá tři hodiny (od 9:00 do 12:00).
      </p>

      <br /><br />

      <p>
        Úlohy jsou uspořádány do stromu jako na obrázku níže:<br />
        (<i style={{ color: "#ea3a60" }}>šifrovací červeně</i>, <i style={{ color: "#75fb4c" }}>matematické zeleně</i> a <i style={{ color: "#0000f5" }}>logické modře</i>)
      </p>

      <br />

      <img src={require("../Assets/images/tree2.webp")} alt="Strom úloh" style={{ width: "30vw" }} />

      <br />

      <p style={{ width: "75vw" }}>
        Strom začíná a končí "obecnou" úlohou. První úloha je napojena na všechny druhy úloh
        <br />
        (<i style={{ color: "#ea3a60" }}>šifrovací</i>, <i style={{ color: "#75fb4c" }}>matematickou</i> i <i style={{ color: "#0000f5" }}>logickou</i>).
        <br /><br />
        Každá další úloha je napojena na dvě úlohy jiného typu, než je ona sama<br />
        - tudíž <i style={{ color: "#75fb4c" }}>matematická</i> úloha je napojena na <i style={{ color: "#ea3a60" }}>šifrovací</i> a <i style={{ color: "#0000f5" }}>logickou</i>, <i style={{ color: "#0000f5" }}>logická</i> na <i style={{ color: "#ea3a60" }}>šifrovací</i> a <i style={{ color: "#75fb4c" }}>matematickou</i> a tak dále.<br />
        <br /><br />
        Strom je rozdělen na 5 vrstev (0 až 4), uspořádaných podle obtížnosti úloh
        <br />
        - první (nejjednodušší) úloha se nachází ve vrstvě 0, poslední ve vrstvě 4.
        <br /><br />
        Vyřešením úlohy se odemknou úlohy přímo napojené na ní
        <br />
        - např. vyřešením úlohy 2 na obrázku se odemknou úlohy 5 a 6.
        <br /><br />
        Za vyřešení úlohy tým dostane 4^(číslo vrstvy) bodů
        <br />
        (0. vrstva = 1 bod za úlohu, 1. vrstva = 4 body, 2. vrstva = 16 bodů, 3. vrstva = 64 bodů, 4 vrstva = 256 bodů).
        <br />
        <img src={require("../Assets/images/tree3.png")} alt="Úlohový strom s body" style={{ width: "30vw" }} />
        <br />
        Formát úlohy je <strong>vždy</strong> zadán úlohou.
        <br /><br />
        K řešení úloh je povoleno používat jakékoli internetové zdroje<br />(jako například{" "}
        <a href="https://www.wolframalpha.com/" target="_blank" rel="noreferrer">WolframAlpha</a>,{" "}
        <a href="https://www.desmos.com/calculator" target="_blank" rel="noreferrer">Desmos</a>,{" "}
        <a href="https://weather.com/" target="_blank" rel="noreferrer">Weather.com</a>{" "}
        aj.), včetně umělé inteligence.
        <br /><br />
        <img src={require("../Assets/images/gifs/2nd.gif")} alt="vysvětlovací gif" />
        <p>
          Celá čísla se zadávají jako arabské číslovky, pokud není uvedeno jinak.
          <br />
          Racionální čísla se zadávají jako zlomek v základním tvaru ve formátu <strong><i>čitatel/jmenovatel</i></strong> (např. tři poloviny by se zapsaly jako 3/2; 4992/40 by se zapsalo jako 624/5). Na krácení zlomků doporučujeme např. <a href="https://www.calculatorsoup.com/calculators/math/fractionssimplify.php" target="_blank" rel="noreferrer">CalculatorSoup</a>.
        </p>
        <br /><br />
        <img src={require("../Assets/images/gifs/3rd.gif")} alt="vysvětlovací gif" />
        <p>
          Textové odpovědi se zadávají malými písmeny bez diakritiky (háčků, čárek atd.).
        </p>
        <br /><br />
        <img src={require("../Assets/images/gifs/5th.gif")} alt="vysvětlovací gif" />
        <p>
          Pokud je řešení úlohy nekonečno, zadejte "inf".
        </p>
        <br /><br />
        Pokud má úloha více řešení, stačí odevzdat jedno. V případě, že by vám nebylo uznáno řešení, kterým jste si opravdu jisti, napište na <a href="mailto:info@smoulasoutez.cz">info@smoulasoutez.cz</a> a v případě správné odpovědi vám budou body přičteny.
        <br /><br />
        <img src={require("../Assets/images/gifs/6th.gif")} alt="vysvětlovací gif" />
        <img src={require("../Assets/images/gifs/7th.gif")} alt="vysvětlovací gif" />
        <br />
        <br />
        <div className="column centerAlign">
          Soutěž vyhrává tým s největším počtem bodů. <i>(opravdu???)</i>
          <br />
          Pokud by se stalo, že by dva týmy měly na konci soutěže stejný počet bodů,
          <br />
          vyhrává ten, který odeslal svojí poslední správnou odpověď jako první.
          <br /><br />
        </div>
      </p>
    </div>
  );
}

export default GameDetailsPage;
